import { connect } from 'react-redux'
import ToursDetails from '../../../pages/main/Programmer/Plannification/Tours/ToursDetails'
import {
  handleCheckAllVisit,
  handleCheckVisit,
  verrouillerTournee,
  deVerrouillerTournee,
} from '../../../redux/simulateTournee/actions'
import {
  getSimulationTours,
  getFocusedTours,
  getSimulationsIsLoading,
} from '../../../redux/simulateTournee/selectors'

const mapStateToProps = (state) => ({
  planning: getSimulationTours(state),
  focusedTours: getFocusedTours(state),
  isRecalculateLoading: getSimulationsIsLoading(state),
})

const mapDisptachToProps = (dispatch) => ({
  handleCheckAll: (indexPlanning) => dispatch(handleCheckAllVisit(indexPlanning)),
  handleCheck: (indexPlanning, indexVisit, RecList) =>
    dispatch(handleCheckVisit(indexPlanning, indexVisit, RecList)),
  verrouillerTournee: (idDriver) => {
    dispatch(verrouillerTournee(idDriver))
  },
  deVerrouillerTournee: (idDriver) => {
    dispatch(deVerrouillerTournee(idDriver))
  },
})

export default connect(mapStateToProps, mapDisptachToProps)(ToursDetails)
