import React, { useEffect } from 'react'
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import './index.scss'
import PropTypes from 'prop-types'
import { Droppable, Draggable } from 'react-beautiful-dnd'
import LongTextComponent from '../../../../main/Orders/Table/LongTextComponent'
import VolumeIcon from '../../../../../assets/icons/cube.svg'
import IdleTimeIcon from '../../../../../assets/icons/polaris/idleTime'
import Checkbox from '@mui/material/Checkbox'
import Badge from '@mui/material/Badge'
import {
  convertirMinutesEnHeuresEtMinutes,
  getCourseType,
} from '../../../../../utils/utils'
import {
  checkActionsToursTable,
  labelsToursTable,
  labelsToursTableOptimisationFile,
  settingsToursTable,
} from './data'
import { Tooltip } from '@mui/material'
import { parseReceptionNumber } from '../../../../../utils/tours'
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined'
import ActionCoursetournee from './actionCoursetournee'
export default function ToursTable({
  tours /* This should be the prop for the new planning coming from driver */,
  index,
  isRecalculateLoading,
  isOpen,
  handleCheck,
  handleCheckAll,
  indexPlanning,
  checkedAll,
  nbVisitChecked,
  isOptimisationCoursesFiles,
  lock,
  planning,
  tour,
  disabledActionMov,
  getPostions,
  deattributeCourse,
}) {
  const [columns, setColumns] = React.useState([])
  const [columsOrder, setColumnsOrder] = React.useState(columns.map((c) => c.name))
  useEffect(() => {
    const columnsKey = isOptimisationCoursesFiles
      ? 'tours_columns_optimationFile'
      : 'tours_columns'
    const columnsList = isOptimisationCoursesFiles
      ? labelsToursTableOptimisationFile
      : labelsToursTable
    const list = JSON.parse(localStorage.getItem(columnsKey))
    if (list) setColumns(list)
    else setColumns(columnsList)
  }, [])
  useEffect(() => {
    setColumnsOrder(columns.map((c) => c.name))
  }, [columns])

  const getChecked = (name) => {
    let isChecked = false
    columns.forEach((column) => {
      if (column.name === name) {
        isChecked = column.checked
      }
    })
    return isChecked
  }

  const initialSimulationTabHeader = (e) => {
    return [
      {
        name: 'receptionNumber',
        content: e?.receptionNumber ? parseReceptionNumber(e.receptionNumber) : '-',
        show: getChecked('receptionNumber'),
      },
      {
        name: 'Type',
        content: e.type ? e.type : '-',
        show: getChecked('Type'),
      },
      {
        name: 'Order',
        content: e.order ? e.order : '0',
        show: getChecked('Order'),
      },
      {
        name: 'Drop Off Order',
        content: e.courseRank || e.courseRank === 0 ? e.courseRank : '-',
        show: getChecked('Drop Off Order'),
      },
      {
        name: 'Type de course',
        content:
          isOptimisationCoursesFiles && e.courseType
            ? e.courseType
            : e.courseType
            ? getCourseType(e.courseType)
            : '-',
        show: getChecked('Type de course'),
      },
      {
        name: 'Lettre de voiture',
        content: e.ldv ? e.ldv : '-',
        show: getChecked('Lettre de voiture'),
      },
      {
        name: 'Heure d’arrivée',
        content: e.arrival ? e.arrival : '-',
        show: getChecked('Heure d’arrivée'),
      },
      {
        name: 'Heure de départ',
        content: e.finish ? e.finish : '-',
        show: getChecked('Heure de départ'),
      },

      {
        name: 'Jour',
        content: e.day ? e.day : '-',
        show: getChecked('Jour'),
      },
      {
        name: 'Créneau de départ',
        content: e.pickup ? e.pickup : '-',
        show: getChecked('Créneau de départ'),
      },
      {
        name: 'Créneau de livraison 1',
        content: e.dropoff1 ? e.dropoff1 : '-',
        show: getChecked('Créneau de livraison 1'),
      },
      {
        name: 'Créneau de livraison 2',
        content: e.dropoff2 ? e.dropoff2 : '-',
        show: getChecked('Créneau de livraison 2'),
      },
      {
        name: "Addresse d'arrivée",
        content: e.location_name ? e.location_name : '-',
        show: getChecked("Addresse d'arrivée"),
      },
      {
        name: 'Volume',
        content: e?.volume >= 0 && e?.volume != null ? e.volume : '-',
        show: getChecked('Volume'),
      },
      {
        name: 'Poids',
        content: e?.weight >= 0 && e?.weight != null ? e.weight : '-',
        show: getChecked('Poids'),
      },

      {
        name: 'Prix',
        content:
          e?.montantPrestataireHT >= 0 && e?.montantPrestataireHT != null
            ? e.montantPrestataireHT
            : '-',
        show: getChecked('Prix'),
      },
      {
        name: 'Canal Vente',
        content: e?.salesChanel?.name ? e?.salesChanel?.name : '-',
        show: getChecked('Canal Vente'),
      },
      {
        name: 'Client',
        content: e?.client ? e?.client : '-',
        show: getChecked('Client'),
      },
      {
        name: "Donneur d'ordre",
        content: e?.orderGiver ? e?.orderGiver : '-',
        show: getChecked("Donneur d'ordre"),
      },
      {
        name: 'Temps prestation',
        content: e?.prestaTime ? e?.prestaTime : '-',
        show: getChecked('Temps prestation'),
      },
      {
        name: 'Code postale',
        content: e?.codePostale ? e?.codePostale : '-',
        show: getChecked('Code postale'),
      },
      {
        name: 'Compétences',
        content: e?.keys ? e?.keys.join(', ') : '-',
        show: getChecked('Compétences'),
      },
    ]
  }
  const getDraggingItemStyle = (draggableStyle, isDragging, type, selected) => {
    return {
      userSelect: 'none',
      padding: 2,
      backgroundColor: isDragging
        ? 'rgba(245,245,245, 0.75)'
        : selected
        ? 'lightgrey'
        : type === 'pickup'
        ? '#E3EFFA'
        : 'none',
      ...draggableStyle,
    }
  }
  const handleCheckAllVisit = (indexPlanning) => {
    handleCheckAll(indexPlanning)
  }

  const handleCheckVisit = (indexPlanning, indexVisit, RecList) => {
    handleCheck(indexPlanning, indexVisit, RecList)
  }
  return (
    <div style={{ width: '100%' }}>
      <Paper
        className="order-table-root"
        style={{ backgroundColor: 'transparent' }}
        key={index}
      >
        <TableContainer
          key={index}
          sx={{
            maxHeight: '300px',
            minHeight: '150px',
          }}
        >
          <Table
            key={index}
            stickyHeader
            aria-label="sticky table"
            sx={{
              boxShadow:
                ' rgb(51 51 51 / 10%) 0px 5px 5px -5px, rgb(51 51 51) 5px 0px 5px -5px',
            }}
          >
            <TableHead>
              {[
                ...(tours?.length > 2 ? checkActionsToursTable : []),
                ...columns,
                ...settingsToursTable,
              ].map(
                (column, index) =>
                  column.checked &&
                  (column.id === 'check' ? (
                    tours?.length > 2 ? (
                      <TableCell key={`column_${index}`} align="center">
                        <Checkbox
                          disabled={lock}
                          checked={checkedAll}
                          onChange={() => {
                            handleCheckAllVisit(indexPlanning)
                          }}
                        />
                      </TableCell>
                    ) : (
                      <TableCell key={`column_${index}`} align="center"></TableCell>
                    )
                  ) : column.id === 'settings' ? (
                    <ActionCoursetournee
                      index={index}
                      planning={planning}
                      tourAction={tour}
                      disabledActionMov={disabledActionMov}
                      getPostions={getPostions}
                      deattributeCourse={deattributeCourse}
                    />
                  ) : (
                    <TableCell key={`column_${index}`} align="center">
                      {column.label}
                    </TableCell>
                  ))
              )}
            </TableHead>

            <Droppable
              droppableId={String(index)}
              direction="vertical"
              isDropDisabled={isRecalculateLoading || !isOpen || lock}
            >
              {/**we can add type prop  for further nested drag and drop usage */}
              {(provided) => (
                <TableBody
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  id={`Tableau-Chauffeur-${index}`}
                >
                  {tours.map((e, i) => {
                    return (
                      <Draggable
                        disabled
                        key={e.location_id.concat(e.type ? e.type : '')}
                        draggableId={e.location_id.concat(e.type ? e.type : '')}
                        index={isOptimisationCoursesFiles ? i + tours.length - 2 : i}
                        isDragDisabled={
                          e.type === undefined ||
                          e.type == 'depotEnd' ||
                          e.type == 'depotStart' ||
                          isRecalculateLoading ||
                          !isOpen ||
                          lock
                        }
                      >
                        {(provided, snapshot) => (
                          <TableRow
                            key={i}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            sx={getDraggingItemStyle(
                              provided.draggableProps.style,
                              snapshot.isDragging,
                              e.type,
                              e.checked
                            )}
                            id={e.ldv ?? e.location_id}

                            // draggable="true"
                          >
                            {e.type !== 'depotEnd' && e.type !== 'depotStart' ? (
                              <TableCell>
                                {snapshot.isDragging ? (
                                  <Badge
                                    badgeContent={e.checked ? nbVisitChecked : 1}
                                    color="primary"
                                    anchorOrigin={{
                                      vertical: 'top',
                                      horizontal: 'left',
                                    }}
                                    style={{ top: '-10px', paddingRight: '5px' }}
                                  ></Badge>
                                ) : (
                                  <Checkbox
                                    disabled={lock}
                                    checked={e.checked}
                                    onChange={() => {
                                      handleCheckVisit(
                                        indexPlanning,
                                        e.indexVisit,
                                        e.receptionNumber
                                      )
                                    }}
                                    component={'td'}
                                    style={{ padding: 0 }}
                                  />
                                )}
                              </TableCell>
                            ) : (
                              (e.type == 'depotEnd' || e.type == 'depotStart') &&
                              tours?.length > 2 && <TableCell></TableCell>
                            )}
                            {initialSimulationTabHeader(e)
                              .sort(
                                (a, b) =>
                                  columsOrder.indexOf(a.name) -
                                  columsOrder.indexOf(b.name)
                              )
                              .filter((element) => element.show)
                              .map((item, index) => {
                                if (item.name == 'Action') {
                                  return (
                                    <TableCell key={index}>
                                      <img src={VolumeIcon} onClick={() => {}} />
                                    </TableCell>
                                  )
                                } else if (
                                  item.name == 'Heure d’arrivée' &&
                                  (e.idleTime > 0 || e.lateBy > 0)
                                ) {
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        <span className="tootltip-content">
                                          {e.idleTime > 0
                                            ? `${convertirMinutesEnHeuresEtMinutes(
                                                e.idleTime
                                              )} avance`
                                            : `${convertirMinutesEnHeuresEtMinutes(
                                                e.lateBy
                                              )} retard`}
                                        </span>
                                      }
                                      arrow
                                    >
                                      <TableCell
                                        sx={{
                                          color: '#932121',
                                        }}
                                      >
                                        <IdleTimeIcon />
                                        <span
                                          style={{ fontSize: '12px !important' }}
                                        >
                                          {e.arrival}
                                        </span>
                                      </TableCell>
                                    </Tooltip>
                                  )
                                } else if (
                                  item.name == 'Heure de départ' &&
                                  e.slotOverRun > 0
                                ) {
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        <span className="tootltip-content">
                                          {`${convertirMinutesEnHeuresEtMinutes(
                                            e.slotOverRun
                                          )} minutes de Dépassement `}
                                        </span>
                                      }
                                      arrow
                                    >
                                      <TableCell
                                        sx={{
                                          color: '#932121',
                                        }}
                                      >
                                        <IdleTimeIcon />
                                        <span
                                          style={{ fontSize: '12px !important' }}
                                        >
                                          {e.finish}
                                        </span>
                                      </TableCell>
                                    </Tooltip>
                                  )
                                } else if (
                                  item.name == 'Order' &&
                                  e?.rejectedKeys?.length > 0 &&
                                  e.order != '0' &&
                                  e.order != `${tours.length - 1} ` &&
                                  isOptimisationCoursesFiles
                                ) {
                                  return (
                                    <Tooltip
                                      key={index}
                                      title={
                                        <span className="tootltip-content">{`${e?.rejectedKeys}`}</span>
                                      }
                                      arrow
                                      alignItems="left"
                                    >
                                      <TableCell
                                        align="left"
                                        sx={{
                                          color: '#f7740f',
                                        }}
                                      >
                                        <WarningAmberOutlinedIcon
                                          sx={{
                                            verticalAlign: 'sub',
                                            marginRight: '3px',
                                          }}
                                        />
                                        <span
                                          style={{ fontSize: '12px !important' }}
                                        >
                                          {e.order}
                                        </span>
                                      </TableCell>
                                    </Tooltip>
                                  )
                                } else
                                  return (
                                    <LongTextComponent
                                      display={true}
                                      text={item.content}
                                      key={index}
                                      style={e.too_late ? { color: 'red' } : {}}
                                      id={`item-${i}-${index}`}
                                    />
                                  )
                              })}
                          </TableRow>
                        )}
                      </Draggable>
                    )
                  })}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

ToursTable.propTypes = {
  tours: PropTypes.array,
  index: PropTypes.number,
  isRecalculateLoading: PropTypes.bool,
  isOpen: PropTypes.bool,
  handleCheck: PropTypes.func,
  handleCheckAll: PropTypes.func,
  indexPlanning: PropTypes.number,
  checkedAll: PropTypes.bool,
  nbVisitChecked: PropTypes.number,
  getCourseType: PropTypes.func,
  isOptimisationCoursesFiles: PropTypes.bool,
  lock: PropTypes.bool,
  planning: PropTypes.any,
  tour: PropTypes.object,
  disabledActionMov: PropTypes.bool,
  getPostions: PropTypes.func,
  deattributeCourse: PropTypes.func,
}
