import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import '../index.scss'
import Button from '@mui/material/Button'
import SimulateMap from '../../../../containers/main/Programmer/simulationMap'
import { getToursInfos } from '../../../../utils'
import ToursDetails from '../../../../containers/main/Simuler/ToursDetails'
import {
  existingPlanning,
  getFleetParse,
  getLocationFromUnservedCourses,
  getParsedCourses,
  locationCheck,
  parseCourseFromUnserved,
  reorderFromUnserved,
  reorderTours,
  validateLocationOnePickUp,
  reorderFromUnservedDeattribute,
  reorderFromUnservedInversionTournee,
  reorderFromUnservedInversionOrderTournee,
} from '../../../../utils/tours'
import { DragDropContext } from 'react-beautiful-dnd'
import UnservedOrders from '../../../../containers/main/Simuler/UnservedOrders'
import { getCourseType } from '../../../../utils/utils'
import DialogAvertissement from '../../../../components/DialogError/dialogAvertissementClose'
import Typography from '@mui/material/Typography'
import Error from '../../../../components/Modal/HttpErrorPopUp'
import CircularProgress from '@mui/material/CircularProgress'
import Dialog from '../../../../components/ModalDialog'
import IconDistribuer from '../../../../assets/icons/polaris/iconeDistribuer'

import './index.scss'

import DriverModal from './components/driverModal'
import { Menu, MenuItem } from '@mui/material'
import FilterIcon from '../../../../assets/icons/iconFilterTableCell'
import { ACCURATE_ADDRESS, filterTab, filterTournee } from '../../../../utils/values'
import { Prompt } from 'react-router-dom/cjs/react-router-dom.min'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCompressAlt, faExpandAlt } from '@fortawesome/free-solid-svg-icons'
import { DetailTounerContainer, MapContainer, TopContainer } from './styled'
import _ from 'lodash'

function MapProgrammer(props) {
  const { planning, unservedTours, isOptimisationCoursesFiles } = props
  //resize windows VERTICAL
  return (
    <MapContainer>
      <SimulateMap
        mapContainerStyle={{
          height: '100%',
          overflow: 'hidden',
          position: 'relative',
        }}
        mapId={''}
        tours={planning.map((tour) => {
          return {
            ...tour,
            color: tour.color,
            markers: tour.markers.map((mark) => {
              return {
                ...mark,
                color: tour.color,
              }
            }),
          }
        })}
        unservedTours={unservedTours}
        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
      />
    </MapContainer>
  )
}
MapProgrammer.propTypes = {
  planning: PropTypes.any,
  unservedTours: PropTypes.any,
  isOptimisationCoursesFiles: PropTypes.bool,
}
export default function Plannification({
  unservedTours,
  createTours,
  handleToursReOrder,
  planning,
  fleet,
  simulateTours,
  handleCheckAll,
  handleCheck,
  checkedAllOrder,
  nbOrderChecked,
  resetCheckedAll,
  changeToursTypeState,
  isOnePickUp,
  viewPlannification,
  isSimLoading,
  isSimError,
  simulationErrorMessage,
  removeError,
  isErrorAffectPlanning,
  activeTourAction,
  isOptimisationCoursesFiles,
  exportTours,
  filtersValues,
  fetchCourses,
  selectedCourses,
  recalculateUnserved,
  focusedTours,
}) {
  const [tabIndex, setTabIndex] = useState(0)
  const [openDeattributeDialog, setOpenDeattributeDialog] = useState(false)
  const [open, setOpen] = useState(false)
  const [openDriverModal, setOpenDriverModal] = useState(false)
  const [messageErreur, setMessageErreur] = useState('')
  const [filtredUnservedTours, setFiltredUnservedTours] = useState({})
  const [selectedFilter, setSelectedFilter] = useState(filterTab[0])
  const [anchorEl, setAnchorEl] = React.useState(null)

  const openFilter = Boolean(anchorEl)
  const handleDragEnd = (result) => {
    const simulate = JSON.parse(JSON.stringify([planning]))

    //mettre les items hors espace drag&drop
    if (!result.destination) {
      return
    }
    //mettre les item au debut de la tournée || au fin de la fin de la tournée
    if (
      (result.destination.index === result.source.index &&
        result.destination.droppableId == result.source.droppableId) ||
      (result.destination.droppableId !== '-1' && result.destination.index === 0) ||
      (result.destination.droppableId !== '-1' &&
        result.source.droppableId === '-1' &&
        result.destination.index ===
          simulate[tabIndex][parseInt(result.destination.droppableId)]?.markers
            ?.length) ||
      (result.destination.droppableId !== '-1' &&
        result.source.droppableId !== '-1' &&
        result.destination.index ===
          simulate[tabIndex][parseInt(result.destination.droppableId)]?.markers
            ?.length) ||
      (result.destination.droppableId === result.source.droppableId &&
        result.destination.index ===
          simulate[tabIndex][parseInt(result.destination.droppableId)]?.markers
            ?.length -
            1)
    ) {
      return
    }

    // drag and drop entre les tableaux de chauffeurs
    if (
      !(
        result.destination.droppableId === '-1' || result.source.droppableId === '-1'
      )
    ) {
      const { simTours, validator } = reorderTours(
        result,
        simulate[tabIndex],
        tabIndex,
        simulate,
        isOnePickUp
      )
      if (!validator) {
        setOpen(true)
        setMessageErreur(
          'Vous ne pouvez pas mettre pickup entre dropoff, Merci de vérifier.'
        )
      } else {
        if (result) {
          handleToursReOrder(
            [simTours],
            fleet.deliveryDate,
            isOnePickUp,
            isOptimisationCoursesFiles
          )
        }
      }
      //drag and drop entre tableau chauffeur && tableau course
    } else if (
      (result.destination.droppableId === '-1' ||
        result.source.droppableId === '-1') &&
      result.destination.droppableId !== result.source.droppableId
    ) {
      const { simTours, newUnservedTours, validator } = reorderFromUnserved(
        result,
        simulate[tabIndex],
        tabIndex,
        simulate,
        [filtredUnservedTours],
        isOnePickUp
      )
      if (!validator) {
        setOpen(true)
        setMessageErreur(
          'Vous avez choisis plusieurs courses avec different point de pickups, Merci de changer le type.'
        )
      } else {
        if (result) {
          handleToursReOrder(
            [simTours],
            fleet.deliveryDate,
            isOnePickUp,
            isOptimisationCoursesFiles
          )
          recalculateUnserved(newUnservedTours)
        }
      }
    }
  }
  const generateTours = () => {
    const toursInfoPayload = getToursInfos(
      [planning],
      isOnePickUp,
      isOptimisationCoursesFiles,
      null
    )
    createTours(toursInfoPayload)
  }

  const generateOptimizedTours = (driverList) => {
    const tours = planning.map((tour) => tour.markers)
    if (!validateLocationOnePickUp(tours[tabIndex], unservedTours, isOnePickUp)) {
      setOpen(true)
      setMessageErreur(
        'Vous avez choisis plusieurs courses avec different point de pickups, Merci de changer le type.'
      )
    } else {
      const driverParse = driverList.map((el) => el.code)

      const checkedFleetList = {
        ...fleet,
        drivers: fleet?.drivers?.filter((e) => driverParse.includes(e.id)),
      }
      const tours = {
        courses: [
          ...getParsedCourses(planning, driverParse),
          ...parseCourseFromUnserved(unservedTours),
        ].flat(),
        ...getFleetParse(
          checkedFleetList,
          getLocationFromUnservedCourses(unservedTours),
          isOnePickUp
        ),
        isFile: isOptimisationCoursesFiles,
      }
      simulateTours(tours, isOnePickUp)
    }
  }

  const filterUnservedOrders = (orders, prevFilter) => {
    if (prevFilter?.label === filterTab[0].label) {
      return orders
    } else {
      return Object.keys(orders)
        .filter((orderId) =>
          prevFilter.label == filterTab[2].label
            ? !ACCURATE_ADDRESS.includes(orders[orderId].dropOffVisit.locationType)
            : selectedCourses?.includes(orderId)
        )
        .reduce((filteredOrders, orderId) => {
          filteredOrders[orderId] = orders[orderId]
          return filteredOrders
        }, {})
    }
  }

  useEffect(() => {
    setSelectedFilter((prevFilter) => {
      const newData = filterUnservedOrders(unservedTours, prevFilter)
      setFiltredUnservedTours(newData)
      return prevFilter
    })
  }, [selectedCourses, selectedFilter, unservedTours])

  const onChangeTourType = () => {
    const checked = existingPlanning(planning, tabIndex, isOnePickUp)
    if (checked != isOnePickUp) changeToursTypeState(checked)
  }

  const onCheckedType = () => {
    return !locationCheck(unservedTours, planning, tabIndex, isOnePickUp)
      ? () => onChangeTourType()
      : () => {
          setOpen(true)
          setMessageErreur(
            'Vous avez choisis plusieurs courses avec different point de pickups, Merci de changer le type.'
          )
        }
  }
  useEffect(() => {
    return () => {
      resetCheckedAll()
    }
  }, [])

  //RESIZE
  const [expandVertical, setExpandVertical] = useState(false)
  const refHorizontalBlock = useRef(null)
  const [expandHorizental, setExpandHorizental] = useState(false)
  const [selectedTourneList, setSelectedTourneList] = useState([])
  //resize VERTICALLY

  const onExpend = (value) => setExpandVertical(value)
  const onExpendHorizental = (value) => setExpandHorizental(value)

  const onDeattribute = () => {
    const simulate = JSON.parse(JSON.stringify([planning]))
    const { simTours, newUnservedTours } = reorderFromUnservedDeattribute(
      focusedTours,
      simulate[tabIndex],
      tabIndex,
      simulate,
      [unservedTours]
    )

    handleToursReOrder(simTours, fleet.deliveryDate, isOnePickUp)
    recalculateUnserved(newUnservedTours)
  }

  useEffect(() => {
    if (isSimLoading) {
      setOpenDeattributeDialog(false)
    }
  }, [isSimLoading])

  const getToursFocused = (simulate, focusedTours) => {
    const result = focusedTours.map((index) => simulate[index].driver.firstName)
    return result
  }

  useEffect(() => {
    if (openDeattributeDialog) {
      setSelectedTourneList(() => getToursFocused(planning, focusedTours))
    }
  }, [openDeattributeDialog])

  const exportFilePlannification = () => {
    const toursInfoPayload = getToursInfos(
      [planning],
      isOnePickUp,
      isOptimisationCoursesFiles
    )
    exportTours(toursInfoPayload)
  }

  const disabledButtonAction = () => {
    return ![planning][0]?.some((tour) => tour.markers.length > 2)
  }
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const getDriverWithTours = () => {
    const driverList = planning
      .filter((tour) => tour.markers.length > 2)
      .map((tour) => tour.driver.id)

    return driverList
  }
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      const confirmationMessage = 'Do you really want to leave?'

      event.returnValue = confirmationMessage
      return confirmationMessage
    }

    window.addEventListener('beforeunload', handleBeforeUnload)

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [])

  const inversionTournee = () => {
    const simulate = _.cloneDeep(planning)
    const { simTours } = reorderFromUnservedInversionTournee(focusedTours, simulate)
    handleToursReOrder([simTours], fleet.deliveryDate, isOnePickUp)
  }

  const inversionOrderTournee = () => {
    const simulate = _.cloneDeep(planning)
    const { simTours } = reorderFromUnservedInversionOrderTournee(
      focusedTours,
      simulate
    )
    handleToursReOrder([simTours], fleet.deliveryDate, isOnePickUp)
  }

  const [selectedAccodions, setSelectedAccodions] = useState([])

  const handleChangeAccordion = (id) => {
    const selectedIndex = selectedAccodions.indexOf(id)
    let newSelected = []

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selectedAccodions, id)
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selectedAccodions.slice(1))
    } else if (selectedIndex === selectedAccodions.length - 1) {
      newSelected = newSelected.concat(selectedAccodions.slice(0, -1))
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selectedAccodions.slice(0, selectedIndex),
        selectedAccodions.slice(selectedIndex + 1)
      )
    }

    setSelectedAccodions(newSelected)
  }
  const handleResetFilter = () => {
    setSelectedFilter(filterTab[0])
    fetchCourses({})
  }

  const reOptimizerTours = () => {
    const simulate = _.cloneDeep(planning)
    const driverParse = []
    focusedTours.map((pos) => {
      driverParse.push(simulate[pos].driver.id)
    })
    const checkedFleetList = {
      ...fleet,
      drivers: fleet?.drivers?.filter((e) => driverParse.includes(e.id)),
    }
    const tours = {
      courses: [...getParsedCourses(simulate, driverParse)].flat(),
      ...checkedFleetList,
      isFile: isOptimisationCoursesFiles,
    }

    simulateTours(tours, isOnePickUp)
  }
  //menu filtre tournee
  const [planningFiltered, setPlanningFiltered] = useState([])
  const [selectedFilterTournee, setSelectedFilterTournee] = useState(
    filterTournee[0]
  )
  const [anchorElTournee, setAnchorElTournee] = useState(null)
  const openFilterTournee = Boolean(anchorElTournee)

  const handleClickTournee = (event) => {
    setAnchorElTournee(event.currentTarget)
  }
  const handleCloseTournee = () => {
    setAnchorElTournee(null)
  }
  const filterTournees = (planning, prevFilter) => {
    const planningClone = _.cloneDeep(
      planning.sort((a, b) => {
        if (a.driver?.firstName < b.driver?.firstName) {
          return -1
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return 1
        }
        return 0
      })
    )
    if (prevFilter?.code === filterTournee[0].code) {
      return planningClone
    } else if (prevFilter?.code === filterTournee[1].code) {
      return planningClone.filter((el, i) => focusedTours.includes(i))
    } else if (prevFilter?.code === filterTournee[2].code) {
      return planningClone.filter((el) => el.markers.length == 2)
    } else if (prevFilter?.code === filterTournee[3].code) {
      return planningClone.filter((el) => el.markers.length > 2)
    } else if (prevFilter?.code === filterTournee[4].code) {
      return planningClone.sort((a, b) => {
        if (a.driver?.firstName < b.driver?.firstName) {
          return -1
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return 1
        }
        return 0
      })
    } else if (prevFilter?.code === filterTournee[5].code) {
      return planningClone.sort((a, b) => {
        if (a.driver?.firstName < b.driver?.firstName) {
          return 1
        }
        if (a.driver?.firstName > b.driver?.firstName) {
          return -1
        }
        return 0
      })
    }
    return planningClone
  }

  useEffect(() => {
    setSelectedFilterTournee((prevFilter) => {
      const newData = filterTournees(planning, prevFilter)
      setPlanningFiltered(newData)
      return prevFilter
    })
  }, [focusedTours, selectedFilterTournee, planning])

  const getDriversOptimiser = () => {
    let drivers = []
    const driverParse = []
    focusedTours.map((pos) => {
      driverParse.push(planning[pos].driver.id)
    })

    drivers = fleet?.drivers
      .filter((driver) => {
        return (
          (driverParse.length > 0 &&
            driverParse.includes(driver.id) &&
            !planning.find((plan) => plan.driver.id === driver.id)?.lock) ||
          (!driverParse.includes(driver.id) &&
            !planning.find((plan) => plan.driver.id === driver.id)?.lock &&
            (driverParse.length == 0 ||
              driverParse.every(
                (driver) => planning.find((plan) => plan.driver.id === driver)?.lock
              )))
        )
      })
      .sort((a, b) => {
        if (a?.firstName < b?.firstName) {
          return -1
        }
        if (a?.firstName > b?.firstName) {
          return 1
        }
        return 0
      })

    return drivers
  }
  return (
    <>
      <Prompt
        when={true}
        message="Veux-tu vraiment partir ? Vos modifications risquent de ne pas être enregistrées."
      />
      {(isSimError || isErrorAffectPlanning) && (
        <Error
          status={'Avertissement'}
          statusText={simulationErrorMessage.message}
          link={simulationErrorMessage.editUrl}
          open={isSimError || isErrorAffectPlanning}
          setOpen={() => {
            removeError(isSimError)
          }}
        />
      )}
      {isSimLoading ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            margin: '30px',
            borderRadius: '10px',
            marginTop: 'calc(100vh - 66vh)',
            width: '100%',
          }}
        >
          <Typography variant="h5" gutterBottom>
            Veuillez patienter la génération de la tournée est en cours
          </Typography>
          <div
            style={{
              marginLeft: '25px',
            }}
          >
            <CircularProgress />
          </div>
        </div>
      ) : (
        !isErrorAffectPlanning && (
          <>
            <div style={{ width: '100%', marginTop: '23px' }}>
              {/* Add Steper and get date index */}
              <>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <div
                    style={{
                      width: '100%',
                      height: '90vh',
                      maxHeight: '90vh',
                      overflow: 'hidden',
                    }}
                  >
                    <div
                      style={{
                        width: '100%',
                        height: '65%',
                        maxHeight: !expandHorizental ? '90vh' : '30vh',
                        background: 'rgb(245 245 245)',
                      }}
                    >
                      <TopContainer>
                        <DetailTounerContainer
                          ref={refHorizontalBlock}
                          expandVertical={expandVertical}
                        >
                          <ToursDetails
                            tabIndex={tabIndex}
                            setTabIndex={setTabIndex}
                            onChecked={onCheckedType()}
                            checked={isOnePickUp}
                            setOpenDeattributeDialog={setOpenDeattributeDialog}
                            isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                            inversionTournee={inversionTournee}
                            expand={expandVertical}
                            onExpend={onExpend}
                            inversionOrderTournee={inversionOrderTournee}
                            selectedAccodions={selectedAccodions}
                            handleChangeAccordion={handleChangeAccordion}
                            reOptimizerTours={reOptimizerTours}
                            //menu filtre tournee
                            filterTournee={filterTournee}
                            planningFiltered={planningFiltered}
                            setSelectedFilterTournee={setSelectedFilterTournee}
                            selectedFilterTournee={selectedFilterTournee}
                            anchorEl={anchorElTournee}
                            openFilter={openFilterTournee}
                            handleClick={handleClickTournee}
                            handleClose={handleCloseTournee}
                            //placement de courses
                            moveCourses={handleDragEnd}
                          />
                        </DetailTounerContainer>
                        <MapProgrammer
                          planning={planning}
                          unservedTours={filtredUnservedTours}
                          isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                        />
                      </TopContainer>
                    </div>

                    <div
                      style={{
                        width: '100%',
                        height: expandHorizental ? '80vh' : '30vh',
                        flexGrow: '1',
                        overflow: 'auto',
                      }}
                    >
                      <div
                        style={{
                          color: '#fff',
                          display: 'inline-flex',
                          alignItems: 'center',
                          background: '#1976d2',
                          height: '45px',
                          width: '100%',
                          padding: '0 10px',
                          justifyContent: 'space-between',
                          position: 'sticky',
                          top: 0,
                          zIndex: '100',
                        }}
                      >
                        <Button
                          id="basic-button"
                          aria-controls={openFilter ? 'basic-menu' : undefined}
                          aria-haspopup="true"
                          aria-expanded={openFilter ? 'true' : undefined}
                          onClick={handleClick}
                          sx={{ color: 'white', textTransform: 'none' }}
                        >
                          <div style={{ display: 'flex', alignItems: 'center' }}>
                            {selectedFilter?.label}
                            <FilterIcon
                              style={{
                                marginLeft: '5px',
                                width: '18px',
                                height: '18px',
                              }}
                            />
                          </div>
                        </Button>
                        <div>
                          {(Object.keys(filtersValues).length > 0 ||
                            selectedFilter != filterTab[0]) && (
                            <Button
                              style={{
                                background: 'white',
                                color: '#1976D2',
                                marginRight: '10px',
                              }}
                              onClick={() => handleResetFilter()}
                            >
                              Réinitialiser le filtre
                            </Button>
                          )}
                          <FontAwesomeIcon
                            style={{ cursor: 'pointer' }}
                            icon={expandHorizental ? faCompressAlt : faExpandAlt}
                            onClick={() => onExpendHorizental(!expandHorizental)}
                          />
                        </div>
                        <Menu
                          id="basic-menu"
                          anchorEl={anchorEl}
                          open={openFilter}
                          onClose={handleClose}
                          MenuListProps={{
                            'aria-labelledby': 'basic-button',
                          }}
                        >
                          {filterTab.map((item, index) => {
                            return (
                              <MenuItem
                                key={index}
                                onClick={() => {
                                  handleClose()
                                  setSelectedFilter(item)
                                }}
                              >
                                {item.label}
                              </MenuItem>
                            )
                          })}
                        </Menu>
                      </div>
                      <UnservedOrders
                        getCourseType={getCourseType}
                        unservedOrders={filtredUnservedTours}
                        handleCheckAll={handleCheckAll}
                        handleCheck={handleCheck}
                        checkedAllOrder={checkedAllOrder}
                        nbOrderChecked={nbOrderChecked}
                        resetCheckedAll={resetCheckedAll}
                        fullScreen={expandHorizental}
                        isOptimisationCoursesFiles={isOptimisationCoursesFiles}
                        filtersValues={filtersValues}
                        setFilters={fetchCourses}
                      />
                    </div>
                  </div>
                </DragDropContext>
              </>
              <div
                style={{
                  width: '100%',
                  height: '50px',
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '0px 20px',
                  backgroundColor: 'white',
                  position: 'fixed',
                  bottom: '0px',
                  zIndex: '100',
                  alignItems: 'center',
                  boxShadow:
                    ' 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%)',
                }}
              >
                <div>
                  <Button
                    onClick={(event) => {
                      viewPlannification(false)
                      activeTourAction()
                      event.stopPropagation()
                    }}
                    sx={{
                      '&.MuiButton-root': {
                        width: '113px',
                        color: '#fff !important',
                        backgroundColor: '#1976D2 !important',
                        textTransform: 'capitalize !important',
                        fontSize: '13px !important',
                        '&:hover': {
                          backgroundColor: 'rgb(17, 82, 147) !important',
                        },
                      },
                      '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                      },
                    }}
                    id={'boutton-sauvgarder-page-planification'}
                  >
                    Retour
                  </Button>
                </div>
                <div>
                  <Button
                    onClick={(event) => {
                      const drivers = [...getDriversOptimiser()].map((el) => {
                        return {
                          code: el.id,
                        }
                      })
                      if (drivers.length == 1 && focusedTours.length == 1) {
                        generateOptimizedTours(drivers)
                      } else {
                        setOpenDriverModal(true)
                      }
                      event.stopPropagation()
                    }}
                    disabled={nbOrderChecked <= 0}
                    sx={{
                      '&.MuiButton-root': {
                        marginRight: '20px !important',
                        width: '113px',
                        textTransform: 'none ',
                        fontSize: '13px !important',
                        backgroundColor: 'white !important',
                        color: '#10A549',
                        border: '1px solid #10A549',
                        ':hover': {
                          backgroundColor: 'white',
                          border: '1px solid #158f44',
                          color: '#158f44',
                        },
                      },
                      '&.Mui-disabled': {
                        color: 'rgba(0, 0, 0, 0.26) !important',
                        backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                        border: '1px solid rgba(0, 0, 0, 0.12)',
                      },
                    }}
                    id={'button-optimiser-page-planification'}
                  >
                    Optimiser
                  </Button>
                  {isOptimisationCoursesFiles ? (
                    <Button
                      disabled={disabledButtonAction()}
                      onClick={(event) => {
                        exportFilePlannification()
                        event.stopPropagation()
                      }}
                      sx={{
                        '&.MuiButton-root': {
                          width: '113px',
                          color: '#fff !important',
                          textTransform: 'capitalize !important',
                          fontSize: '13px !important',
                          backgroundColor: '#10A549',
                          ':hover': {
                            backgroundColor: '#158f44',
                          },
                        },
                        '&.Mui-disabled': {
                          color: 'rgba(0, 0, 0, 0.26) !important',
                          backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                        },
                      }}
                      id={'button-exporter-page-planification'}
                    >
                      Exporter
                    </Button>
                  ) : (
                    <Button
                      onClick={(event) => {
                        generateTours()
                        event.stopPropagation()
                      }}
                      sx={{
                        '&.MuiButton-root': {
                          marginRight: '20px !important',
                          width: '113px',
                          color: '#fff !important',
                          textTransform: 'capitalize !important',
                          fontSize: '13px !important',
                          backgroundColor: '#10A549',
                          ':hover': {
                            backgroundColor: '#158f44',
                          },
                        },
                        '&.Mui-disabled': {
                          color: 'rgba(0, 0, 0, 0.26) !important',
                          backgroundColor: 'rgba(0, 0, 0, 0.12) !important',
                        },
                      }}
                      id={'button-sauvgarder-page-planification'}
                    >
                      Sauvegarder
                    </Button>
                  )}
                </div>
              </div>
            </div>
            {openDriverModal ? (
              <DriverModal
                selectedCourses={selectedCourses}
                openDriverModal={openDriverModal}
                setOpenDriverModal={setOpenDriverModal}
                generateOptimizedTours={generateOptimizedTours}
                drivers={[...getDriversOptimiser()]}
                notEmptyTours={getDriverWithTours()}
              />
            ) : null}
            {open && (
              <DialogAvertissement
                open={open}
                messageErreur={messageErreur}
                onClose={() => {
                  setOpen(false)
                  setMessageErreur('')
                }}
              />
            )}
          </>
        )
      )}
      {openDeattributeDialog && (
        <Dialog
          maxWidthDialog={'md'}
          open={openDeattributeDialog}
          title={'Désattribuer tournée'}
          style={isSimError || isErrorAffectPlanning ? { display: 'none' } : {}}
          iconTitle={
            <IconDistribuer
              style={{
                width: '20px',
                height: '20px',
                marginRight: '9px',
              }}
            />
          }
          content={
            <div style={{ padding: '30px' }}>
              {`Voulez vous vraiment désattribuer ${
                selectedTourneList.length == 1 ? 'la tournée' : 'les tournées'
              }? "${selectedTourneList.join(', ')}"`}
            </div>
          }
          handleClose={() => {
            setOpenDeattributeDialog(false)
          }}
          handleClickAction={() => {
            onDeattribute()
          }}
        />
      )}
    </>
  )
}
Plannification.propTypes = {
  unservedTours: PropTypes.any,
  /* reorder tours function */
  handleToursReOrder: PropTypes.func,
  /* Planning data */
  planning: PropTypes.any,
  /* Planning data coming from the store */
  fleet: PropTypes.any,
  /* optimization action */
  simulateTours: PropTypes.func,
  createTours: PropTypes.func,
  viewPlannification: PropTypes.func,
  handleCheckAll: PropTypes.func,
  handleCheck: PropTypes.func,
  checkedAllOrder: PropTypes.bool,
  nbOrderChecked: PropTypes.number,
  resetCheckedAll: PropTypes.func,
  changeToursTypeState: PropTypes.func,
  isOnePickUp: PropTypes.any,
  isSimLoading: PropTypes.bool,
  isSimError: PropTypes.bool,
  simulationErrorMessage: PropTypes.object,
  removeError: PropTypes.func,
  isErrorAffectPlanning: PropTypes.bool,
  activeTourAction: PropTypes.func,
  hideImpossibleCourses: PropTypes.any,
  setHideImpossibleCourses: PropTypes.func,
  planification: PropTypes.bool,
  isOptimisationCoursesFiles: PropTypes.bool,
  exportTours: PropTypes.func,
  filtersValues: PropTypes.object,
  fetchCourses: PropTypes.func,
  selectedCourses: PropTypes.array,
  recalculateUnserved: PropTypes.func,
  focusedTours: PropTypes.array,
}
