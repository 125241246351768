import React, { useEffect, useState } from 'react'
import Dialog from '../ModalDialog'
import Grid from '@mui/material/Grid'
import FormControl from '@mui/material/FormControl'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import TextField from '@mui/material/TextField'
import MenuItem from '@mui/material/MenuItem'

import moment from 'moment'
import { ORDER_TYPE } from '../../constants/appContants'

import PropTypes from 'prop-types'
import DatePickerLocalisation from '../DatePickerLocalisation'
import OptionsList from '../../components/ShipmentOptionsList'
import { getDaysDisabledDays, getOpeningDay } from '../../utils/getDisabledDays'
import DatePickerDisabledCouleur from '../DatePickerDisabledCouleurs'
import { COURSE_SOURCE_SCHMIDT, ClientDelivery } from '../../utils/values'
import {
  COURSE_SOURCE_BLOQUAGE_CHECK_SEND_MAIL_PROGRAMMATION,
  COURSE_SOURCE_CALENDAR,
  COURSE_SOURCE_CALENDAR_DISABLED,
} from '../../utils/checkActionCourseSource'

export default function ProgramDialogDetails({
  hideDialog,
  open,
  programmCourses,
  selectedCourses,
  selectedShipments,
  getRecommendedCreneau,
  recommendedCreneauList,
  getDayDispo,
  rdvDispo,
  userId,
  isLoadingActionOrder,
  errorActionCourse,
  isLoadingRecommendedCreneau,
  resetRdvDispo,
  getCreneauRecommendedDisponibilites,
  getConfigCanal,
  configCanal,
  resetConfigCanal,
  getDayDisponibilites,
}) {
  const [options, setOptions] = useState([])
  const [notificationOption, setNotificationOption] = useState({
    mail: !(
      COURSE_SOURCE_BLOQUAGE_CHECK_SEND_MAIL_PROGRAMMATION.includes(
        selectedShipments[0].courseSource
      ) || selectedShipments[0].codeCanalVente?.includes('BUT-')
    ),
  })
  const [date, setDate] = useState({
    dropOffStart: '',
    dropOffEnd: '',
    day: moment(new Date()).format('YYYY/MM/DD'),
    creneauSelected: '',
  })
  const [disabledDays, setDisabledDays] = useState([])
  const [openedDays, setOpenedDays] = useState([])

  useEffect(() => {
    if (selectedShipments && selectedShipments.length > 0) {
      let codeCanalVente = selectedShipments[0].codeCanalVente
      getConfigCanal(codeCanalVente, 'apiDispo')
    }
    return () => {
      resetConfigCanal()
    }
  }, [])

  useEffect(() => {
    handleDisabledDays()
  }, [selectedShipments, configCanal])

  const resetForm = () => {
    setDate({ dropOffStart: '', dropOffEnd: '', day: '', creneauSelected: '' })

    setNotificationOption({
      mail: false,
    })
  }
  useEffect(() => {
    return () => {
      resetForm()
    }
  }, [open])

  let courseSourceExist =
    COURSE_SOURCE_CALENDAR.includes(selectedShipments[0].courseSource) ||
    selectedShipments[0].codeCanalVente?.includes('BUT-')
  let courseType = ['StoreDelivery', 'StorePickUp'].includes(
    selectedShipments[0].courseType
  )
  const isSchmit = selectedShipments[0].courseSource === COURSE_SOURCE_SCHMIDT

  const handleDisabledDays = () => {
    if (configCanal?.length > 0 || configCanal == null) {
      let days = getDaysDisabledDays(selectedShipments[0])

      const adresses = ClientDelivery.includes(selectedShipments[0].courseType)
        ? selectedShipments[0].adresseArrivee
        : selectedShipments[0].adresseDepart
      let codePostal = adresses.postalCode

      if (configCanal?.length > 0) {
        const serviceTime = selectedShipments[0].serviceTime
        const idCanal = selectedShipments[0].idCanalVente
        const dateDebut = moment(new Date()).format('YYYY-MM-DD')
        const dateFin = selectedShipments[0].codeCanalVente?.includes('BUT-')
          ? moment(dateDebut).add(14, 'd').format('YYYY-MM-DD')
          : moment(dateDebut).add(2, 'M').endOf('month').format('YYYY-MM-DD')
        getDayDisponibilites(dateDebut, dateFin, idCanal, serviceTime, codePostal)
        setDisabledDays([])
      } else if (!courseSourceExist || (courseType && isSchmit)) {
        const openDays = getOpeningDay(selectedShipments[0])
        setOpenedDays(openDays)
        setDisabledDays(days)
      } else {
        const dateDebut = moment(new Date()).format('YYYYMMDD')
        const dateFin = moment(dateDebut)
          .add(2, 'M')
          .endOf('month')
          .format('YYYYMMDD')
        getDayDispo(dateDebut, dateFin, codePostal)
        setDisabledDays(days)
      }
    }
  }
  useEffect(() => {
    if (rdvDispo.length > 0) {
      const tabRdvDispo = rdvDispo.map(
        (rdv) => new Date(moment(rdv.split('\t')[0]).format('YYYY/MM/DD'))
      )
      setOpenedDays(tabRdvDispo)
    }
  }, [rdvDispo])
  useEffect(() => {
    return () => {
      resetRdvDispo()
    }
  }, [])
  useEffect(() => {
    if (
      selectedShipments &&
      selectedShipments.length > 0 &&
      date.day !== '' &&
      (configCanal?.length > 0 || configCanal == null)
    ) {
      const dateSelected = moment(date.day).format('YYYY-MM-DD')

      if (configCanal?.length > 0) {
        const serviceTime = selectedShipments[0].serviceTime
        const idCanal = selectedShipments[0].idCanalVente
        const adresses = ClientDelivery.includes(selectedShipments[0].courseType)
          ? selectedShipments[0].adresseArrivee
          : selectedShipments[0].adresseDepart
        const codePostal = adresses.postalCode
        getCreneauRecommendedDisponibilites(
          dateSelected,
          serviceTime,
          idCanal,
          codePostal
        )
      } else {
        const codeCanal = selectedShipments[0].codeCanalVente
        getRecommendedCreneau(dateSelected, codeCanal)
      }
    }
  }, [date.day, configCanal])
  const handleProgram = () => {
    let optionSend = options
      .filter((option) => option.quantity > 0)
      .map(({ id, quantity }) => {
        return {
          idOption: id,
          quantity: quantity,
        }
      })
    const sendMail = notificationOption.mail
    let data = {
      courseSource: selectedShipments[0].courseSource,
      userId: String(userId),
      dateDemarrage: moment(new Date(date.day).setHours(0, 0, 0, 0))
        .add('hour', parseInt(date.creneauSelected.start, 10))
        .toISOString(),
      dateDemarrageMeta: date.creneauSelected.dateDemarrageMeta,
      timeSlotId: date.creneauSelected?.timeSlotId,
      codeCourse: selectedCourses[0],
      nonEnvoiMail: !sendMail,
      options: optionSend,
      notify: sendMail,
    }

    programmCourses(data)
  }
  const handleQuntityChange = (e, id) => {
    const { value } = e.target
    if (options && options.length) {
      const optionsList = [...options]
      const op = optionsList.findIndex((o) => o.id === id)
      if (op >= 0) {
        optionsList[op].quantity = !isNaN(value) ? value : 0
        optionsList[op].totalPrice = optionsList[op].quantity * optionsList[op].price
        setOptions(optionsList)
      }
    }
  }
  useEffect(() => {
    if (selectedShipments.length === 1) {
      if (selectedShipments[0]?.optionsDetails?.length > 0) {
        setAssemblyOptions(
          selectedShipments[0].optionsDetails,
          selectedShipments[0].options
        )
      }
    }
  }, [selectedShipments])

  const setAssemblyOptions = (deliveryOptions, options) => {
    const optionsDetails = deliveryOptions.filter(
      (op) => op.orderService === ORDER_TYPE.DELIVERY
    )
    if (optionsDetails && optionsDetails.length) {
      let optionsForm = optionsDetails.map((el) => {
        return {
          id: el.id,
          label:
            el.label && el.label !== null
              ? `${el.label.charAt(0).toUpperCase()}${el.label.slice(1)}`
              : null,
          quantity: checkQuatitePrix(el, options).quantity,
          price: el.price,
          totalPrice: checkQuatitePrix(el, options).price,
        }
      })
      setOptions(optionsForm)
    }
  }
  const checkQuatitePrix = (optionsDetails, options) => {
    if (options.length > 0) {
      var index = options?.map((opt) => opt.option.id).indexOf(optionsDetails.id)

      if (index !== -1)
        return {
          quantity: options[index].quantity,
          price: options[index].quantity * optionsDetails.price,
        }
    }

    return { quantity: 0, price: 0 }
  }

  useEffect(() => {
    if (errorActionCourse) hideDialog()
  }, [errorActionCourse])
  return (
    <Dialog
      maxWidthDialog={'md'}
      open={open}
      title={"Programmation d'une livraison"}
      content={
        <div
          style={{ paddingRight: '16px', paddingBottom: '16px', minWidth: '600px' }}
        >
          {!(
            COURSE_SOURCE_BLOQUAGE_CHECK_SEND_MAIL_PROGRAMMATION.includes(
              selectedShipments[0].courseSource
            ) || selectedShipments[0].codeCanalVente?.includes('BUT-')
          ) && (
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormControlLabel
                  control={
                    <Checkbox
                      id={'notifier-program'}
                      checked={notificationOption.mail}
                      onChange={(e) => {
                        setNotificationOption({
                          ...notificationOption,
                          [e.target.name]: !notificationOption[e.target.name],
                        })
                      }}
                      name="mail"
                    />
                  }
                  label="Notifier le destinataire par SMS/mail"
                />
              </FormControl>
            </Grid>
          )}
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <div className="text" style={{ paddingBottom: '10px' }}>
                  Créneau
                </div>

                <Grid
                  alignContent="center"
                  justify="flex-start"
                  alignItems="center"
                  container
                  spacing={2}
                >
                  <Grid item xs={12} sm={1}>
                    <span>Le</span>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    {!courseSourceExist || (courseType && isSchmit) ? (
                      <DatePickerLocalisation
                        id={'date-program'}
                        disablePast={true}
                        handleDateChange={($event) =>
                          setDate({
                            day: $event,
                            creneauSelected: '',
                            dropOffStart: '',
                            dropOffEnd: '',
                          })
                        }
                        withDefaultValue={true}
                        defaultValue={date.day}
                        label="Date "
                        disabledDays={disabledDays}
                        openedDays={openedDays}
                      />
                    ) : (
                      <DatePickerDisabledCouleur
                        id={'date-program'}
                        disablePast={true}
                        handleDateChange={($event) =>
                          setDate({
                            day: $event,
                            creneauSelected: '',
                            dropOffStart: '',
                            dropOffEnd: '',
                          })
                        }
                        withDefaultValue={true}
                        defaultValue={date.day}
                        label="Date "
                        disabledDays={disabledDays}
                        forceDisabledDays={
                          COURSE_SOURCE_CALENDAR_DISABLED.includes(
                            selectedShipments[0].courseSource
                          ) || selectedShipments[0].codeCanalVente?.includes('BUT-')
                        }
                        openedDays={openedDays}
                        minDate={moment(new Date()).format('YYYY/MM/DD')}
                        maxDate={moment(new Date())
                          .add(2, 'M')
                          .endOf('month')
                          .format('YYYY/MM/DD')}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <FormControl className="dropdown-loader">
                      <TextField
                        select
                        label="Heure "
                        name="creneauSelected"
                        value={date.creneauSelected}
                        className="input-textfield"
                        disabled={
                          (configCanal?.length > 0 &&
                            (selectedShipments[0].codeCanalVente?.includes('BUT-') ||
                              COURSE_SOURCE_CALENDAR_DISABLED.includes(
                                selectedShipments[0].courseSource
                              )) &&
                            openedDays.length <= 0) ||
                          isLoadingRecommendedCreneau
                        }
                        onChange={(e) => {
                          const dropOffStart = e.target.value.start.split(':')[0]
                          const dropOffEnd = e.target.value.end.split(':')[0]
                          setDate({
                            ...date,
                            dropOffStart,
                            dropOffEnd,
                            creneauSelected: e.target.value,
                          })
                        }}
                        variant="outlined"
                        id={'heure-program'}
                      >
                        {recommendedCreneauList &&
                        recommendedCreneauList.length > 0 ? (
                          recommendedCreneauList.filter(
                            (h) =>
                              !(
                                new Date().toLocaleDateString() ===
                                  new Date(date.day).toLocaleDateString() &&
                                (new Date().getHours() + 2 >=
                                  parseInt(h.end[0] + h.end[1]) ||
                                  new Date().getHours() + 2 >=
                                    parseInt(h.start[0] + h.start[1]))
                              )
                          ).length !== 0 ? (
                            recommendedCreneauList.map((h, index) => {
                              if (
                                !(
                                  new Date().toLocaleDateString() ===
                                    new Date(date.day).toLocaleDateString() &&
                                  (new Date().getHours() + 2 >=
                                    parseInt(h.end[0] + h.end[1]) ||
                                    new Date().getHours() + 2 >=
                                      parseInt(h.start[0] + h.start[1]))
                                )
                              ) {
                                return (
                                  <MenuItem
                                    value={h}
                                    key={index}
                                    id={`heure-program-${index}`}
                                  >
                                    {h.start} - {h.end}
                                  </MenuItem>
                                )
                              }
                            })
                          ) : (
                            <MenuItem value="" id={`heure-program-none`}>
                              <em>None</em>
                            </MenuItem>
                          )
                        ) : (
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                        )}
                      </TextField>
                    </FormControl>
                  </Grid>
                </Grid>
              </FormControl>
            </Grid>

            {options.length > 0 && (
              <Grid item xs={12}>
                <div className="text">Options</div>
                <OptionsList
                  options={options}
                  handleQuantityChange={handleQuntityChange}
                />
              </Grid>
            )}
          </Grid>
        </div>
      }
      handleClose={hideDialog}
      handleClickAction={handleProgram}
      disabled={date.creneauSelected == ''}
      isLoadingButtonAction={isLoadingActionOrder}
    />
  )
}
ProgramDialogDetails.propTypes = {
  open: PropTypes.bool,
  hideDialog: PropTypes.func,
  programmCourses: PropTypes.func,
  courseNumber: PropTypes.number,
  selectedCourses: PropTypes.array,
  selectedShipments: PropTypes.array,
  salesChannelCourses: PropTypes.bool,
  getRecommendedCreneau: PropTypes.func,
  recommendedCreneauList: PropTypes.array,
  getDayDispo: PropTypes.func,
  rdvDispo: PropTypes.array,
  userId: PropTypes.string,
  isLoadingActionOrder: PropTypes.bool,
  errorActionCourse: PropTypes.string,
  isLoadingRecommendedCreneau: PropTypes.bool,
  resetRdvDispo: PropTypes.func,
  getCreneauRecommendedDisponibilites: PropTypes.func,
  getConfigCanal: PropTypes.func,
  configCanal: PropTypes.array,
  resetConfigCanal: PropTypes.func,
  getDayDisponibilites: PropTypes.func,
}
